@import '../../styles/variables.scss';

.container {
	position: fixed;
	top: 10vh;
	right: 40px;
	width: 400px;
	height: 80vh;
	border-radius: 10px;
	box-shadow: 0 2px 10px 1px #b5b5b5;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	z-index: 30;
}

.reconnection {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: white;
	-webkit-animation: blink 0.8s ease infinite alternate;
	animation: blink 0.8s ease infinite alternate;
	z-index: 31;
}

@-webkit-keyframes blink {
	0% {
		background-color: rgba(0, 0, 0, .4);
	}

	to {
		background-color: rgba(0, 0, 0, .3);
	}
}

@keyframes blink {
	0% {
		background-color: rgba(0, 0, 0, .4);
	}

	to {
		background-color: rgba(0, 0, 0, .3);
	}
}