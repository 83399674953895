@import '../../styles/variables.scss';

.conditionDataBlock {
	margin-bottom: 4px;
	padding: 3px 16px 12px;
	border: none;
	border-radius: 4px;
	background: $backgroundColorCondition;

	&Active {
		background-color: $colorSecondaryLight2;
	}

	&CursorMove {
		cursor: move;
	}

	&Opacity {
		opacity: 0;
	}

	&>legend {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 8px;

		.legendBlock {
			display: flex;
			gap: 8px;

			&>button {
				padding: 2px 6px;
				border-radius: 4px;
				border: $borderSection;
				background-color: $shadowColorBlockInfo;
				color: inherit;
				font-size: inherit;
				transition: filter 0.2s;

				&:not(:disabled) {
					color: $colorSecondary;

					&:hover {
						cursor: pointer;
						filter: brightness(90%);
					}
				}

				&:focus-visible {
					outline: 2px solid $colorSecondary;
				}
			}
		}
	}

	&Line {
		margin-block: 10px 2px;
		text-align: center;
		position: relative;
		border: 1px solid transparent;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 40%;
			width: calc(10% - 16px);
			border-top: $borderSection;
		}

		&::after {
			left: initial;
			right: 40%;
		}

		&Title {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.areaToCaptureMovement {
	margin-bottom: 8px;
	text-align: center;
	font-weight: normal;
	cursor: move;

	&:active {
		cursor: grabbing;
	}
}