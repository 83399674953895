@import '../../../styles/variables.scss';

.header {
	padding: 20px 30px;
	width: 100%;
	border-radius: 10px 10px 0 0;
	background-color: $colorSecondary;
	font-weight: normal;
	font-size: 24px;
	color: #fff;
	text-overflow: ellipsis;
	overflow: hidden;
	cursor: move;

	&:active {
		cursor: grabbing;
	}

	&Wrapper {
		position: relative;
	}
}

.buttonCloseChat {
	position: absolute;
	top: 50%;
	right: 20px;
	border: none;
	outline: none;
	background: transparent;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 32;
}