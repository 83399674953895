@import '../../../styles/variables.scss';

.conditionDataBlock {
	margin-bottom: 4px;
	padding: 3px 16px 8px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorCondition;

	&Opacity {
		opacity: 0.5;
	}

	&>legend {
		padding-inline: 6px;
		border: $borderSection;
		border-radius: 4px;
		background-color: $shadowColorBlockInfo;
	}

	&Line {

		&Button {
			margin-inline: auto;
			padding: 2px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			border-radius: 4px;
			background-color: transparent;
			color: inherit;
			font-size: inherit;

			&:focus-visible {
				outline: 2px solid $colorSecondary;
			}

			&:not(:disabled):hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
}