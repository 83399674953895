@import '../../../styles/variables.scss';

.condition {
	display: flex;
	gap: 12px;
	align-items: center;

	&TrashCan {
		margin-top: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 4px;
		background-color: transparent;
		cursor: pointer;
		transition: 0.2s;
		will-change: scale;

		&:hover {
			scale: 1.1;
		}

		&:focus-visible {
			outline: 2px solid $colorSecondary;
		}
	}
}