@import '../../styles/variables.scss';

.container {
	padding: 24px;
	max-height: 95vh;
	text-align: left;
	overflow: auto;
}

.header {
	margin-bottom: 10px;
	color: $colorPrimary;
	text-align: center;
	cursor: move;
}