@import '../../styles/variables.scss';

.container {
	display: flex;
	flex-direction: column;
	padding: 3px 16px 16px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorRow;
	cursor: move;
	position: relative;

	&Active {
		background-color: $colorSecondaryLight2;
	}

	&Opacity {
		opacity: 0;
	}

	&PaddingTop {
		padding-top: 9px;
	}

	&Active {
		background-color: $colorSecondaryLight2;
	}
}

.legend {
	display: flex;
	gap: 8px;
	text-align: right;

	&Button {
		padding: 2px 6px;
		display: flex;
		align-items: center;
		border-radius: 4px;
		border: $borderSection;
		background-color: $shadowColorBlockInfo;
		color: inherit;
		font-size: inherit;
		transition: filter 0.2s;

		&:not(:disabled) {
			color: $colorSecondary;

			&:hover {
				cursor: pointer;
				filter: brightness(90%);
			}
		}

		&:focus-visible {
			outline: 2px solid $colorSecondary;
		}
	}

	&Block {
		padding: 2px 6px;
		display: flex;
		gap: 10px;
		border: $borderSection;
		border-radius: 4px;
		background-color: $shadowColorBlockInfo;

		& .legendButton {
			padding: 0;
			border: none;
			transition: transform 0.2s;
			will-change: transform;

			&:hover {
				filter: none;
				transform: scale(1.1);
			}
		}
	}
}

.actionBlock {
	display: flex;
	align-items: center;
	gap: 12px;

	&Opacity {
		opacity: 0.5;
	}

	&NoDataTitle {
		font-size: 13px;
	}
}